import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import SignUpForm from './components/user/new';
import Layout from './components/page/layout';
import Root from './components/root';
import reportWebVitals from './reportWebVitals';

// import { LastLocationProvider } from 'react-router-last-location';
import Chatbox from './components/chatbox/chatbox';

const root = ReactDOM.createRoot(document.getElementById('app'));
root.render(
  <div>
  <React.StrictMode>
    <Root>
    </Root>
  </React.StrictMode>
  <Chatbox/>
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from 'react';
import AudioRecorderPolyfill from 'audio-recorder-polyfill';

// use rest(post or get) to avoid sdk blank issue on iphone 
// import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';
import toWav from 'audiobuffer-to-wav';
// import { getWaveBlob } from 'webm-to-wav-converter';




export class VoiceRecognition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRecording: false,
      audioURL: null,
      binaryString: null,
      arrayBuffer: null,
      transcript: "",
      error: null
    };
    this.mediaRecorder = null;
    this.audioChunks = [];
  }

  componentDidMount() {
    // for iphone
    if (!window.MediaRecorder) {
      window.MediaRecorder = AudioRecorderPolyfill;
    }

    this.audioContext = new (window.webkitAudioContext||window.AudioContext)();

    // audio
    // window.AudioContext = new AudioContext();
    // if (window.AudioContext == null)
    //  window.AudioContext = new WebkitAudioContext;
  }

  arrayBufferToBinaryString(buffer) {
    const bytes = new Uint8Array(buffer);
    let binaryString = '';
    for (let i = 0; i < bytes.byteLength; i++) {
      binaryString += String.fromCharCode(bytes[i]);
    }
    return binaryString;
  }

  startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      
      if (MediaRecorder.isTypeSupported('audio/webm')) {
        this.mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm' });
      } else {
        this.mediaRecorder = new MediaRecorder(stream);
      }

      this.audioChunks = [];

      this.mediaRecorder.addEventListener('dataavailable', (event) => {
        this.audioChunks.push(event.data);
      });

      this.mediaRecorder.addEventListener('stop', async() => {
        console.log("chunck_",this.audioChunks);

        console.log('Recorded audio MIME type:', this.mediaRecorder.mimeType);
        const audioBlob = new Blob(this.audioChunks, { type: this.mediaRecorder.mimeType });

        /*
        // Blob (Binary Large Object) is an object that represents a file-like object of immutable, raw data.
        // To show the content of a Blob, you need to read it.
        // if want to show its value, just convert to ArrayBuffer
        const arrayBuffer = await audioBlob.arrayBuffer();
        // size is the number of bytes not binary
        console.log("audioBlob_",audioBlob.size,"_",audioBlob, new Uint8Array(arrayBuffer));
        */

        const audioURL = URL.createObjectURL(audioBlob);
        this.setState({ "audioURL":audioURL });

        // convert it to binary string
        const reader = new FileReader();
        reader.onloadend = () => {
          const arrayBuffer = reader.result;
          // Convert ArrayBuffer to binary string
          // const binaryString = this.arrayBufferToBinaryString(arrayBuffer);
          // "binaryString":binaryString, 
          this.setState({ "arrayBuffer":arrayBuffer },
              (()=>{
                // must use call back to make sure thit.state.arrayBuffer not null 
                // make post request
                // Uint8Array is a typed array that represents an array of 8-bit unsigned integers.
                // console.log("buffer_type_",arrayBuffer.constructor.name, arrayBuffer.length, arrayBuffer, Array.from(new Uint8Array(arrayBuffer)));
                this.sendAudioToAzure(arrayBuffer);
              }));
          // Log or use the binary string
          // console.log('Binary String:', binaryString);


          
        };

      reader.readAsArrayBuffer(audioBlob); // Reads the Blob as ArrayBuffer
      });

      this.mediaRecorder.start();
      this.setState({ isRecording: true, error: null });
    } catch (error) {
      console.error('Error accessing microphone:', error);
      this.setState({ error: 'Error accessing microphone. Please check permissions.' });
    }
  };

  stopRecording = () => {
    if (this.mediaRecorder && this.state.isRecording) {
      this.mediaRecorder.stop();
      this.setState({ isRecording: false });
    }
  };

  playRecording = () => {
    if (this.state.audioURL) {
      const audio = new Audio(this.state.audioURL);
      audio.play().catch(error => console.error('Error playing audio:', error));
    }
  };


  // when async is added in the function, invoke must add await
  resampleAudioBuffer = async(audioBuffer, targetSampleRate) => {
    
    // console.log(audioBuffer.sampleRate);

    const originalSampleRate = audioBuffer.sampleRate;
    const ratio = targetSampleRate / originalSampleRate;
    const newLength = Math.round(audioBuffer.length * ratio);
    const channel_num = 1;//for laptop, audioBuffer.numberOfChannels = 2,but set = 1 works good
    const resampledBuffer = new AudioBuffer({
      length: newLength,
      numberOfChannels:  channel_num,
      sampleRate: targetSampleRate
    });
    // console.log("#channel=",audioBuffer.numberOfChannels);
    // console.log("result", result.constructor,name);
    // Copy and resample the data
    for (let channel = 0; channel < channel_num; channel++) {
      // separate works better,  both 0 and 1 are fine, 0 is better than 1
      const originalData = audioBuffer.getChannelData(channel);
      const resampledData = resampledBuffer.getChannelData(channel);
      for (let i = 0; i < resampledData.length; i++) {
        const originalIndex = Math.floor(i * originalSampleRate / targetSampleRate);
        resampledData[i] =  originalData[originalIndex]; // very small, Math.floor make it disappear
        //console.log(resampledData[i]);
      }
    }/**/

    /*
    // merge works better ?... 
    const resampledData = resampledBuffer.getChannelData(0);
    for (let i = 0; i < resampledData.length; i++) { resampledData[i] = 0.0;}
    for (let channel = 0; channel < audioBuffer.numberOfChannels; channel++) {
      const originalData = audioBuffer.getChannelData(channel);
      for (let i = 0; i < resampledData.length; i++) {
        const originalIndex = Math.floor(i * originalSampleRate / targetSampleRate);
        resampledData[i] += originalData[originalIndex]; // very small, Math.floor make it disappear
        //console.log(resampledData[i]);
      }
    }*/


  
    /*
    // linear also works fine, only channel_num matters, single!
    for (let channel = 0; channel < channel_num; channel++) {
      const inputData = audioBuffer.getChannelData(channel);
      const outputData = resampledBuffer.getChannelData(channel);
  
      for (let i = 0; i < newLength; i++) {
        const position = i / ratio;
        const index = Math.floor(position);
        const fraction = position - index;
  
        if (index + 1 < inputData.length) {
          // Linear interpolation
          outputData[i] = inputData[index] * (1 - fraction) + inputData[index + 1] * fraction;
        } else {
          outputData[i] = inputData[index];
        }
      }
    }
    */



    // paly to test
    // this.audibuffer_playtest(resampledBuffer);
  
    return resampledBuffer;

    /*
    const offlineContext = new OfflineAudioContext(1, audioBuffer.duration * targetSampleRate, targetSampleRate);
    const source = offlineContext.createBufferSource();
    source.buffer = audioBuffer;
    source.connect(offlineContext.destination);
    source.start(0);
    const resampled16kBuffer = await offlineContext.startRendering();

    return resampled16kBuffer;*/
  }

  resampleAudioBuffer_iphone = async(audioBuffer, targetSampleRate) => {
    // effective parameter: (2, 44100, 44100)
    const originalSampleRate = audioBuffer.sampleRate;
    const targetSampleRate_ = targetSampleRate; // 
    const ratio = targetSampleRate_ / originalSampleRate;
    const newLength = Math.round(audioBuffer.length * ratio);

    const audioContext = this.audioContext;// error here, look like it takes time new window.webkitAudioContext({sampleRate: targetSampleRate});
    const resampledBuffer = await audioContext.createBuffer(
      audioBuffer.numberOfChannels,
      newLength,
      targetSampleRate_ // iphone only support >= 22050
    );
    // Copy and resample the data
    for (let channel = 0; channel < audioBuffer.numberOfChannels; channel++) {
      const originalData = audioBuffer.getChannelData(channel);
      const resampledData = resampledBuffer.getChannelData(channel);
      for (let i = 0; i < resampledData.length; i++) {
        const originalIndex = Math.floor(i * originalSampleRate / targetSampleRate_);
        resampledData[i] = originalData[originalIndex] ;
      }
    }

    // target sample
    // alert(audioBuffer.numberOfChannels); // 1
    // alert(audioBuffer.duration * targetSampleRate); // 

    // paly to test
    // this.audibuffer_playtest(resampledBuffer);
    

    return resampledBuffer;
  }

  audibuffer_playtest(resampledBuffer){
    // play it to test the sound for voice recognition
    const audioContext = this.audioContext;
    const source = audioContext.createBufferSource();
    source.buffer = resampledBuffer;
    source.connect(audioContext.destination);
    source.start(0);
  }

  // audioBuffer is PCM(real voice impluse), wav is file format to wrap PCM
  PCM_azure_voice = async (resampled16kBuffer, ResampleRate) => {
    // Convert AudioBuffer to WAV format (16-bit PCM)
    const wavBuffer = toWav(resampled16kBuffer, { 
      sampleRate: ResampleRate, // 16000,
      float32: false,  // ensures 16-bit PCM
    });


    const blob = new Blob([wavBuffer], { type: 'audio/wav' });
    const formData = new FormData();
    formData.append('file', blob, 'audio.wav');


    const response = await fetch('https://eastus.stt.speech.microsoft.com/speech/recognition/conversation/cognitiveservices/v1?language=en-US&format=detailed', {
      method: 'POST',
      headers: {
        'Ocp-Apim-Subscription-Key': '4d817a3d82544003bd5955d6500baafd',
        'Content-Type': 'audio/wav'
      },
      body: formData
    });

    const result = await response.json();
    console.log("Azure API Response:", result);

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    if (result.DisplayText) {
      // this.setState({ transcript: result.DisplayText });
      if (this.props.message_set){
        this.props.message_set(result.DisplayText);
      }
    } else {
      this.setState({ error: 'No text recognized' });
    }
  }

  sendAudioToAzure = async (arrayBuffer) => {
    //try {

      // var audioContext = null;
      const audioContext = this.audioContext;
      if (window.AudioContext){
        const ResampleRate = 16000;
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer); 
        const resampled16kBuffer = await this.resampleAudioBuffer(audioBuffer, ResampleRate);
        this.PCM_azure_voice(resampled16kBuffer, ResampleRate);
      } else {
        this.setState({ transcript: "iphone" });
        //alert(audioContext.decodeAudioData);
        // alert(arrayBuffer);
        // iphone safari doing with by call back, not await
        audioContext.decodeAudioData(arrayBuffer, 
          async (decodedData) => { // decodedData = audioBuffer
            // Success callback
            const ResampleRate = 24000; // 48000 not working, we'd better have 16000 
            const resampled16kBuffer = await this.resampleAudioBuffer_iphone(decodedData, ResampleRate);
            this.PCM_azure_voice(resampled16kBuffer, ResampleRate);

            // this.PCM_azure_voice(decodedData, decodedData.sampleRate);
          },
          function(error) {
            // Error callback
            alert('Decoding failed:', error);
          }
        );

      
    
    }
      
  
    /*} catch (error) {
      this.setState({ error: 'Error sending audio to Azure: ' + error.message });
    }*/
  };

  resampleAudio = async(audioBuffer, targetSampleRate) => {
    const numChannels = audioBuffer.numberOfChannels;
    const originalSampleRate = audioBuffer.sampleRate;
    const originalLength = audioBuffer.length;
    const newLength = Math.round(originalLength * targetSampleRate / originalSampleRate);
    
    // Create a new AudioBuffer with the target sample rate
    const offlineContext = new window.webkitOfflineAudioContext(
        numChannels,
        newLength,
        targetSampleRate
    );
    
    const newBuffer = offlineContext.createBuffer(numChannels, newLength, targetSampleRate);

    for (let channel = 0; channel < numChannels; channel++) {
        const originalData = audioBuffer.getChannelData(channel);
        const newData = newBuffer.getChannelData(channel);

        let lastIndex = 0;
        for (let newIndex = 0; newIndex < newLength; newIndex++) {
            const originalIndex = newIndex * originalSampleRate / targetSampleRate;
            const index1 = Math.floor(originalIndex);
            const index2 = Math.min(index1 + 1, originalLength - 1);
            
            // Simple linear interpolation
            const fraction = originalIndex - index1;
            newData[newIndex] = originalData[index1] + fraction * (originalData[index2] - originalData[index1]);
            
            lastIndex = index2;
        }
    }

    return newBuffer;
}

  render() {
    const { isRecording, audioURL, error } = this.state;

    var res =  (
    <div>
      <h2>Audio Recorder</h2>
      <p>{this.state.transcript}</p>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <button onClick={isRecording ? this.stopRecording : this.startRecording}>
        {isRecording ? 'Stop Recording' : 'Start Recording'}
      </button>
      {/*for replay*/}
      {audioURL && (
        <div>
          <button onClick={this.playRecording}>Play Recording</button>
          <audio src={audioURL} controls />
        </div>
      )}
    </div>);

    // glyphicon glyphicon-volume-off
    // glyphicon glyphicon-send
    // var button_show = <span className="glyphicon glyphicon-send"></span>; 
    // MouseDown not work in iphone safari
    // <button onMouseDown={this.startRecording} onMouseUp={this.stopRecording} style={{"cursor":"pointer","margin": "auto"}}>
    var icon_microphone= <svg width="28" height="28" viewBox="0 0 17 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.9 10.8C16.3556 10.8 16.7322 11.1386 16.7918 11.5778L16.8 11.7V12.3C16.8 16.5713 13.494 20.0705 9.3012 20.378L9.3 23.1C9.3 23.597 8.89704 24 8.4 24C7.94436 24 7.5678 23.6614 7.50816 23.2222L7.5 23.1V20.3782C3.39988 20.0779 0.147396 16.7256 0.00487175 12.5836L0 12.3V11.7C0 11.203 0.402948 10.8 0.9 10.8C1.35564 10.8 1.73219 11.1386 1.79178 11.5778L1.8 11.7V12.3C1.8 15.6924 4.48134 18.4585 7.84032 18.5947L8.1 18.6H8.7C12.0924 18.6 14.8585 15.9187 14.9947 12.5597L15 12.3V11.7C15 11.203 15.403 10.8 15.9 10.8ZM8.4 0C11.0509 0 13.2 2.14903 13.2 4.8V12C13.2 14.6509 11.0509 16.8 8.4 16.8C5.74903 16.8 3.6 14.6509 3.6 12V4.8C3.6 2.14903 5.74903 0 8.4 0ZM8.4 1.8C6.74316 1.8 5.4 3.14315 5.4 4.8V12C5.4 13.6568 6.74316 15 8.4 15C10.0568 15 11.4 13.6568 11.4 12V4.8C11.4 3.14315 10.0568 1.8 8.4 1.8Z" fill="#123BB6"></path></svg>;
    var icon_ok = <span className="glyphicon glyphicon-ok" style={{width:"28px", height:"28px"}}></span>
    var res_2 = (isRecording)?(
        <button onClick={this.stopRecording} style={{"cursor":"pointer","margin": "auto"}}>
          {icon_ok}
        </button>
      ):(
        <button onClick={this.startRecording} style={{"cursor":"pointer","margin": "auto"}}>
          {icon_microphone}
        </button>
      );

    return res_2;
  }

}


export default VoiceRecognition;



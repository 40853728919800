function lang_to_server_(lang){
    
    // remote server
    const json = {
        "action": "language",
        "lang_": lang
    };

    const formData = new URLSearchParams();
    for (const key in json) {
        formData.append(key, json[key]);
    }
    fetch("/action/", {
        method: "POST",
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData // must stringify
    })
        .then((response) => response.json())
        .then((data) => {
            // console.log(data);
            console.log({...json, ...data});

            // none ?... already reload, do not have chance to show ?
            if (("ifsuccess" in data) && (data["ifsuccess"]=="true")){
            } else {
                alert(data["message"]);
            }
        })
        .catch((error) => {
            console.error("Error update language in the server:", error);
        });
}

let lang_ = localStorage.getItem('lang_');
// local storage is accessible (the same) in all browser,  but different for different domain
if (lang_ !== null){
    // remote server
    lang_to_server_(lang_);
} else { // first time
    lang_ = 'English'; // set default value in case not show properly
    fetch('https://ipinfo.io/json')
    .then(response => response.json())
    .then(data => {
        const country = data.country;
        if (country == 'CN'){
            lang_ = '中文';
        } else { // all other is English
            lang_ = 'English';
        }

        // locally
        localStorage.setItem('lang_', lang_);
        window.location.reload(); // ---> !=null, send remote there --> remote
        // localStorage.removeItem('lang_');
    });
}

/*
The country property returned by the IPinfo API represents the two-letter country code according to the ISO 3166-1 alpha-2 standard. Here are a few examples of country codes for some countries:

    United States: "US"
    United Kingdom: "GB"
    Canada: "CA"
    Germany: "DE"
    France: "FR"
    Australia: "AU"
    Japan: "JP"
    Brazil: "BR"
    India: "IN"
    China: "CN"
    Russia: "RU"
*/


let dict_s = {
    'English':{},
    '中文':{
        // layout
        "setting": "设置",

        // log in, sign up
        "information can be omitted": "信息不能为空" ,
        "first name": "名",
        "already log out": "退出下线了",
        "Error!": "",
        "password":"密码",
        "log in": "登录",
        "log out": "退出登录",
        "no account yet ?": "还没有帐号?",
		"email or phone number": "邮箱或手机号码",
		"sign up !": "注册一个!",
        "password must be the same !": "两次密码不一致",
		"information can't be empty": "信息不能为空",
        "last name": "姓",
        "account(phone number, email, qq, can't be repeated)": "帐号(手机,邮箱,qq)(不可重复)",
        "confirm password": "密码确认",
        "register a new account": "帐号注册",
		"create account": "确认创建帐号",
		"already have an account ?": "已经有帐号了?",

        // layout
        "you logged in": "您已经登录了",
        "you need to log in": "您需要先登录",
        
        // talking
        "add talking": "添加新对话",
        "Error add a new talking" : "添加新对话时出现错误",
        "OK": "好的",
        "edit": "编辑",
        "remove": "删除",
        "add": "添加",
        "type in the message here": "这里输入消息...",
        "send": "发送",
        "please choose a talking": "请选择一个对话",
        "unexpected error in sending talking": "发送对话时出现意外错误",
        "Error sending talking: ": "发送对话时出现错误: ",

        // email
        "write a new email": "  "
    }
}

for (let key in dict_s["中文"]){
    dict_s['English'][key] = key;
}

let lang_s_ = ['English', '中文'];

let dict_ = dict_s[lang_];

export {lang_, lang_s_, dict_, lang_to_server_};

import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom'; 

import { restapi } from '../action';

// language option
import {dict_} from '../language';

class Logout extends Component{

	constructor(props) {
        super(props);

        this.state = {isLoged: true};

		this.change_state = this.change_state.bind(this);
    }

	change_state(json) {
        if ("action" in json) { // this return from a post request
            switch (json["action"]) {
                case "logout":
                    this.setState({ isLoged: !json["ifsuccess"]});

                    break;
            }
        } 
    }

	//sign out from firebase, reroute to login page
	componentDidMount() {
		/*
		firebase.auth().signOut();
		hashHistory.push('/login');
		*/

		function logout_(change_state){
			var json = {
				"action": "logout"
			};
		
			restapi(json, change_state.bind(null));
		}

		logout_(this.change_state);
	}

	render(){
		if (this.state.isLoged){
		return <p>{dict_["already log out"]}</p>;
		} else {
			window.location.assign('/login');
			return <Navigate to="/login"  />
		}
	}
};

export default Logout;
import React, { Component } from 'react';
import {dict_} from './language';

// to show code like chatgpt
// reference in :  https://github.com/react-syntax-highlighter/react-syntax-highlighter
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import docco from 'react-syntax-highlighter/dist/esm/styles/hljs/docco';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism'; // Choose a style (e.g., darcula)


import MarkdownRenderer from './markdown';
// import ReactMarkdown from 'react-markdown';  // not work in iphone safari
// import Markdown from 'markdown-to-jsx'; // for markdown...works be very slow, untolerable
// import snarkdown from 'snarkdown';  // wired

// import { marked } from 'marked'; // can't show properly
// import DOMPurify from 'dompurify';
// return <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(marked(seg.content)) }} />;
/*
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
*/

class Dialog extends Component{

    constructor(props) {
        super(props);

        /**/
        this.state = {
        };
    }


    ai_post(post, index) { // need to add key={index} for react tracking them
        var dateTimeCustomization = {
            year: "numeric", month: "short",
            day: "numeric", hour: "2-digit", minute: "2-digit"
        };

        var content;

        function text_split(text){ // split to
            // 
            var seg = text.split('\n\n');
            var content_seg = [];
            for (let i=0; i<seg.length; i++)
                content_seg.push({content:seg[i]});

            return content_seg;
        }

        
        content = post.content;

        /*
        console.log("content__\r\n"+content);
        console.log("total__"+seg.length);
        // for (let i=0; i<seg.length; i++)
        //     console.log(seg[i]);
        console.log("0__\r\n"+seg[0]);
        var content_seg = text_split(seg[0]); //[{content:seg[0]}];
        for(let i=1;i<seg.length;i++){
            var first = seg[i].indexOf("\n");
            var language;
            if(first > 0){
                language = seg[i].substring(0,first);
            } else { // bash is defalut ???....''' is python !
                // default is not sure, just leave it empty (not code style)
                // azure open ai is not sure
                language = "";//"bash"; 
            }
            var last = seg[i].lastIndexOf("```");
            var code, rest;
            code = seg[i].substring(first+1,last);
            rest = seg[i].substring(last+3);
            console.log(i+"__\r\n"+seg[i]);
            console.log("break____0\r\n"+code+"\r\n"+"break____1\r\n"+rest);
            content_seg.push({content:code,language:language});
            content_seg.push(...text_split(rest)) ;//({content:rest});
        }
        console.log(content_seg);
        */

        // \n\n'''   \n\n```    different !!
        // not necessarily \n```  maybe \n     ```javascript
        var seg = content.split("```"); // just start and end with \n```
        var content_seg = [];
        for(let i=0;i<seg.length;i++){
            if(i % 2 == 1){ // code
                var first = seg[i].indexOf("\n");
                var language;
                if(first > 0){
                    language = seg[i].substring(0,first);
                } else { // bash is defalut ???....''' is python !
                    // default is not sure, just leave it empty (not code style)
                    // azure open ai is not sure
                    language = "";//"bash"; 
                }
                var code = seg[i].substring(first+1);
                content_seg.push({content:code,language:language});
            } else { // text
                content_seg.push(...text_split(seg[i])) ;//({content:rest});
            }
        }



        return (
        <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <table>
                <tbody>
                    {/*<tr>
                        <td style={{ padding: '0 0 0 5px', color: '#9f9f9f', verticalAlign: 'top' }}>
                            {(new Date(post.created_at)).toLocaleTimeString("zh-CN", dateTimeCustomization)}
                        </td>
                    </tr>*/}

                    <tr>
                        <td rowSpan='2' style={{ padding: '0 5px 0 0' }}>
                            <img src="ai.jpg" width="80" height="80" style={{ objectFit: 'cover' }} />
                        </td>
                    </tr>

                </tbody>
            </table>
            <div style={{ backgroundColor: "white", color: "blue", padding: "8px", border: "1px solid black", borderRadius: "16px" }}>
                {/*post.content
                <SyntaxHighlighter language="python" style={dark}>
                {post.content}
                </SyntaxHighlighter>
                */}
                {content_seg.map((seg, index) => (
                    seg.language ?(
                        <div key={index}>
                        <p/>
                        {/*here must use () , instead of {}; must make sure this comment is within <div> parent !*/
                        /* here must use '' for property*/}
                        <div style={{ display: 'flex', alignItems: 'center', position: 'relative', color: '#777', backgroundColor: '#f5f5f5', padding: '8px 16px', fontSize: '12px', fontFamily: 'sans-serif', justifyContent: 'space-between', borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }}>
                            {seg.language}
                        </div>
                        <SyntaxHighlighter language={seg.language} style={darcula}>
                            {seg.content}
                        </SyntaxHighlighter>
                        </div>
                        ):(
                        <div key={index}>
                        {/*<Markdown>{seg.content}</Markdown>  <p/> {seg.content}*/}
                        <MarkdownRenderer markdown={seg.content} />
                        </div>)
                ))}

                <br /> {/*received*/}
            </div>
        </div>);
    }

    self_post(post, index) {
        var dateTimeCustomization = {
            year: "numeric", month: "short",
            day: "numeric", hour: "2-digit", minute: "2-digit"
        };

        return (
        <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <pre style={{ backgroundColor: "blue", color: "white", padding: "8px", border: "1px solid white", borderRadius: "16px", whiteSpace: 'pre-wrap'}}>
                {/*<div dangerouslySetInnerHTML={{ __html: post.content.replace('\n', '<br />') }} />*/}
                {post.content}
            {/*sent*/}
            </pre>
            <table>
                <tbody>
                    {/*<tr>
                        <td style={{ padding: '0 0 0 5px', color: '#9f9f9f', verticalAlign: 'top' }}>
                            {(new Date(post.created_at)).toLocaleTimeString("zh-CN", dateTimeCustomization)}
                        </td>
                    </tr>*/}

                    <tr>

                        <td rowSpan='2' style={{ padding: '0 5px 0 0' }}>
                            <img src="user.jpg" width="80" height="80" style={{ objectFit: 'cover' }} />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>);
    }


    render(){
        /* Chat List*/
        /*100vh means 100% of the viewport height, so an element with this value will be exactly as tall as the browser window's visible area.*/
        // React components will automatically re-render when their props change.
        // 
        return(
            <div className="chat-box" style={{overflowY: "auto", height:this.props.height}} ref={this.props.chat_box}>
                <div className="chat-messages">
                    {/*only if the height is fix*/}
                    {/*if call this.function, then no {}*/}
                    {this.props.chat_list != null ? this.props.chat_list.map((post, index) => (
                        post.human ?
                            this.self_post(post, index)
                            :
                            this.ai_post(post, index)
                    ))
                        :
                        <div></div>}
                </div>
            </div>
        );
    }
};

export default Dialog;



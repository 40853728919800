import React, { Component } from 'react';
import { Link } from 'react-router-dom'; 

class Search extends Component{
    
    constructor(props) {
        super(props);

        this.state = {search_target:""};

        this.search = React.createRef();
    }


    handleSearch(e) {
        /*
        e.preventDefault();
        if(this.refs.search.value != ""){
            var path = "results/" + this.refs.search.value;
            hashHistory.push(path);
        }*/

        if(e.key == 'Enter'){
			try{
                // alert("search !");
                if (this.search.current.value){
                    this.setState({search_target: "/results/" + this.search.current.value})
                    // can't redirect here  !!!!!!!!!!!!!
                   //  alert("search !__"+"/results/" + this.search.current.value);
                   //  window.location.assign("user/2"); //("/results/" + this.search.current.value);
                    // ("results/2");//
                    
                } else {
                    alert("you must type in the keyword");
                }
			}
			catch(e){};
		}
    }


    handleAdvancedSearchForm() {
        // hashHistory.push('/advanced');
    }

    componentWillMount() {
        this.setState({ isRecruiter: this.props.isRecruiter });
    }

    componentWillReceiveProps(nextProps) {
        alert(nextProps.isRecruiter);
        this.setState({ isRecruiter: nextProps.isRecruiter });
    }

    render() {
        // alert(this.state.isRecruiter);
        if (this.state.search_target){
            window.location.assign(this.state.search_target);
        } else {
        var advancedSearch;
            if (true){ //this.state.isRecruiter) {
                advancedSearch = <button className="btn btn-link" onClick={this.handleAdvancedSearchForm.bind(this)}>
                    <Link style={{ color: "white" }} to="advanced">高级搜索</Link>
                    </button>;
            } else {
                advancedSearch = null;
            }

            return (
                <form className="navbar-form pull-left" onKeyDown={this.handleSearch.bind(this)}>
                    <div className="form-group">
                        <input type="text" ref={this.search} className="form-control navbar-search" placeholder="找人..." />
                    </div>
                    {advancedSearch}
                </form>
            )
        }
    }
};


export default Search;


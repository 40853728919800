import React from 'react';

class CompanyInfo extends React.Component {
  render() {
    const {companyName, location, street, contact, website } = this.props;

    return (
      <div className="company-info-container">
        <p><strong></strong> {companyName}</p>
        <p><strong></strong> {location}</p>
        <p>{street}</p>
        <p>{contact}</p>
        <p><strong></strong> <a href={"https://"+website}>{website}</a></p>
      </div>
    );
  }
}

export default CompanyInfo;

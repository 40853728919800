import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';

import { restapi, restapi_2 } from '../action';

// language option
import {dict_} from '../language';

class NewUser extends Component {

	constructor(props) {
		super(props);

		this.firstName = React.createRef();
		this.lastName = React.createRef();
		this.email = React.createRef();
		this.password = React.createRef();
		this.password_confirmation = React.createRef();

		this.change_state = this.change_state.bind(this);

		this.state = {
			hasError: false,
			isLoged: false
		};
	}

	//initially, no submission errors
	componentWillMount() {

		this.setState({ hasError: false });
		return { hasError: false };
	}

	// change the state, according to the response json
	change_state(json) {
		if ("action" in json) { // this return from a post request
			switch (json["action"]) {
				case "register":
					this.setState({
						hasError: !json["ifsuccess"],
						isLoged: json["ifsuccess"]
					});

					break;
			}
		}
	}
	

	handleSignUp() {
		var that = this;

		//gets the data from the form fields
		const firstName = this.firstName.current.value;
		const lastName = this.lastName.current.value;
		const email = this.email.current.value;
		const password = this.password.current.value;
		const password_confirmation = this.password_confirmation.current.value;

		if (firstName && lastName) {
			//creates the user on firebase
			/*
			firebase.auth().createUserWithEmailAndPassword(email, password == password_confirmation ? password : "nil").catch(function(error) {
				if(error){
					that.setState({hasError: true});
					that.setState({errorMsg: "Please enter a valid email address with a password of at least 6 characters."});
				}
			});*/
			if (password == password_confirmation) {

				function check_signup(change_state) {
					var json = {
						"action": "register",
						"first": firstName,
						"last": lastName,
						"username": email,
						"password": password
					};

					restapi(json, change_state.bind(null));
				}

				check_signup(this.change_state);

			} else {
				this.setState({ hasError: true });
				this.setState({ errorMsg: dict_["password must be the same !"]});
					// "Please enter a valid email address with a password of at least 6 characters." });
			}
			
		} else {
			this.setState({ hasError: true });
			this.setState({ errorMsg: dict_["information can't be empty"] });
				// "请输入有效的帐号,至少6位的密码,选择工会,"});
				//"First or last name field cannot be empty." })
		}

		//if successfully logged in, add the user child to the database with the name and email.
		/*
		this.unsubscribe = firebase.auth().onAuthStateChanged(function(user) {
			if (user) {
				var userData = {
					email: email,
					first: firstName,
					last: lastName,
					recruiter: recruiter,
					imageURL: "https://firebasestorage.googleapis.com/v0/b/testingproject-cd660.appspot.com/o/images%2Fdefault.jpg?alt=media&token=23d9c5ea-1380-4bd2-94bc-1166a83953b7",
					interests: "",
					skills: ""
				};

				firebase.database().ref('users/' + firebase.auth().currentUser.uid).set(userData);

				//update display name for user
				user.updateProfile({
					displayName: firstName + " " + lastName,
				});

				hashHistory.push("/");
			}
		});*/
	}

	componentWillUnmount() {
		/*
		if (typeof this.unsubscribe == 'function')
		{
			this.unsubscribe();
		}
		*/
	}

	//if "Enter" was pressed, act as Sign Up was clicked
	handleKeyPress(e) {
		if (e.key == 'Enter') {
			try {
				this.handleSignUp();
			}
			catch (e) { };
		}
	}

	//sets the recruiter state true or false depending on the radio button
	accountChange(e) {
		this.setState({ recruiter: e.target.value });
	}

	//creates a div alert-danger with the error message
	errorMessage() {
		return <div className="alert alert-danger"><strong>Error! </strong>{this.state.errorMsg}</div>;
	}

	//creates an empty div if no error message
	noErrorMessage() {
		return <div></div>;
	}

	render() {
		//gets the appropriate error alert div depending on whether or not the form has an error
		var errorAlert;
		if (this.state.hasError) {
			errorAlert = this.errorMessage();
		} else {
			errorAlert = this.noErrorMessage();
		}

		var info = <div>
		<input type="text" ref={this.firstName} placeholder={dict_["first name"]} className="form-control" onKeyDown={this.handleKeyPress.bind(this)} /><br />
		<input type="text" ref={this.lastName} placeholder={dict_["last name"]} className="form-control" onKeyDown={this.handleKeyPress.bind(this)} /><br />
		<input type="email" ref={this.email} placeholder={dict_["account(phone number, email, qq, can't be repeated)"]} className="form-control" onKeyDown={this.handleKeyPress.bind(this)} /><br />
		<input type="password" ref={this.password} placeholder={dict_["password"]} className="form-control" onKeyDown={this.handleKeyPress.bind(this)} /><br />
		<input type="password" ref={this.password_confirmation} placeholder={dict_["confirm password"]} className="form-control" onKeyDown={this.handleKeyPress.bind(this)} /><br />
		</div>;



		if (!this.state.isLoged) {
			return (
				<div>
					{errorAlert}

					<div className="col-md-4">
					</div>

					<div className="col-md-4 margin-top-30">
						<center>
							<h1>{dict_["register a new account"]}</h1><br />
							<div className="sign-up-form">
								{info}
								<button onClick={this.handleSignUp.bind(this)} className="btn btn-primary margin-bottom-10">{dict_["create account"]}</button><br />
								{dict_["already have an account ?"]} <Link to="/login" text="Login!"> {dict_["log ini"]} </Link>
							</div>
							
						</center>
					</div>
					<div className="col-md-4">
					</div>
				</div>
			);
		} else {
			window.location.assign('/');
			return <Navigate to="/" />
		}
	}
}

export default NewUser;
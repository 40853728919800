import React, { Component } from 'react';
import {dict_} from './language';



class List extends Component{

    constructor(props) {
        super(props);

        /**/
        this.state = {
            talking_list: [],
            talking_active_th: -1,
            talking_edit_th: -1,
            th_id: 0, // current active friend's th, default is 0 (first)
            talking_id: -1, // current active talking's id
        };


        // for edit
        this.edit_box = React.createRef();
    }


    initial_setting() {
        const json = {
            "action": "list",
            "ops": "load",
            "list_name": this.props.list_name,
            // "user_id": -1 // has to pass a value...not used at all
        };

        const formData = new URLSearchParams();
        for (const key in json) {
            formData.append(key, json[key]);
        }
        fetch("/action/", {
            method: "POST",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData // must stringify
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                console.log({ ...json, ...data });
                if (("ifsuccess" in data) && (data["ifsuccess"] == "true")) {
                    this.setState({ talking_list: data["talking_list"], self_id: data["self_id"] });
                }

                // establish the socket for each friend
                /*
                var talking_list = data["talking_list"];
                // add message_  
                for (let i = 0; i < friends.length; i++) {
                    var msg_name = "messages_" + friends[i]["user_id"];
                    this.setState({ [msg_name]: [] });
                }

                
                var self_id = data["self_id"];
                var socket_list = [];
                for (let i = 0; i < friends.length; i++) {
                    var user_id = friends[i]["user_id"];
                    // 'ws://your-domain/ws/chat/room-name/'
                    // must use absolute url, or "An invalid or illegal string was specified"
                    // thus, have to modify it manually 
                    const socket_url = "ws://" + window.location.hostname + ':8000/chatroom/' + Math.min(user_id, self_id) + "_" + Math.max(user_id, self_id) + "/";

                    console.log(socket_url);
                    // maybe due to re-render...has several websocket, thus double receiving
                    const newSocket = new WebSocket(socket_url); // , 'echo-protocol'  
                    socket_list.push(newSocket); // unshift for start

                    // listen to the incoming message
                    newSocket.onmessage = async (event) => {
                        try {
                            const data = JSON.parse(event.data);
                            if ("user_id" in data) {
                                // receive and add to the chat list
                                const l = this.state.messages.length;
                                // check if identical to avoid repeated websocket since inevitable in react
                                if (l == 0 || data.created_at != this.state.messages[l - 1].created_at) {
                                    // no him self
                                    if (data.user_id != this.state.self_id) {
                                        // console.log("receive new !");
                                        // var messages = this.state.messages;
                                        // this.setState({ messages: [...messages, { ...data, user_id: this.state.user_id }] });
                                        var msg_name = "messages_" + data.user_id;
                                        this.setState({ [msg_name]: [...this.state[msg_name], data] },
                                            () =>{ // scroll down for current
                                                if (data.user_id == this.state.user_id && this.chat_box.current) {
                                                    // scroll the chat
                                                    const chat_box = this.chat_box.current;
                                                    chat_box.scrollTop = chat_box.scrollHeight;
                                                }
                                            });
                                    } else { // sent message
                                        // must get the time from server
                                        var msg_name = "messages_" + this.state.user_id;
                                        
                                        // alert(msg_name);
                                        // console.log(msg_name);
                                        // console.log(this.state);
                                        // console.log(this.state[msg_name])
                                        
                                        this.setState({ [msg_name]: [...this.state[msg_name], data] }, 
                                            () => { // scroll down for current
                                                if (this.chat_box.current) {
                                                    // scroll the chat
                                                    const chat_box = this.chat_box.current;
                                                    chat_box.scrollTop = chat_box.scrollHeight;
                                                }
                                            });
                                    }

                                } else {
                                    // alert("blocked");
                                }
                            } else if (("special_type" in data) && (data["special_type"] == "history")) {
                                // for load history
                                this.setState(data);
                                console.log(data);
                            }
                        } catch (error) {
                            console.error("Error handling socket message:", error);
                        }

                    };

                    newSocket.onclose = function (e) {
                        // this.setState({status:"bad"});
                        cache["status"] = "bad";
                        console.error('Chat socket closed unexpectedly');
                    };
                }
                // alert(socket_list.length);
                this.setState({ socket_list: socket_list, friend_active_th: cache.th });
                
                cache["status"] = "Good";
                */
            })
            .catch((error) => {
                console.error("Error fetch list "+this.props.list_name+":", error);
            });
    }

    componentWillMount() {
        this.initial_setting();
    }

    /*
    incrementNumber() {
        // this.setState(prevState => ({ number: prevState.number + 1 })); // Increment the number by 1
        // this.setState({ number: this.state.number + 1 });
        if (cache["status"] == "bad") { // disconnected --> reconnect again
            cache["th"] = this.friend_active_th;
            this.initial_setting();
            // why active_friend_th do not get maintained ??? also reset ???
        }
    }

    componentDidMount() {
        // pageshow,  visibilitychange not work
        // window.addEventListener('visibilitychange', this.handleFocus.bind(this));

        // Call the incrementNumber function every second
        const intervalId = setInterval(this.incrementNumber.bind(this), 2000); // 1000 milliseconds = 1 second
    }
    

    componentWillUnmount() {
        if (this.state.socket_list) {
            // alert("close !");
            for (let i = 0; i < this.state.socket_list.length; i++)
                this.state.socket_list[i].close();
            this.setState({ socket_list: null });
        }
    }
    */


    handle_talking_add(index){
        const json = {
            "action": "list",
            "ops": "add",
            "list_name": this.props.list_name,
            // "user_id": -1 // has to pass a value...not used at all
        };

        const formData = new URLSearchParams();
        for (const key in json) {
            formData.append(key, json[key]);
        }
        fetch("/action/", {
            method: "POST",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData // must stringify
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                console.log({ ...json, ...data });
                if (("ifsuccess" in data) && (data["ifsuccess"] == "true")) {
                    var talking_list = this.state.talking_list;
                    // do not use append, not update automatically, push works
                    talking_list.push({...data});
                    this.setState({ talking_list: talking_list,
                        talking_edit_th: talking_list.length-1},
                        () => { // make sure happen after 
                            /*
                            if (this.chat_box.current) {
                                // scroll the chat
                                const chat_box = this.chat_box.current;
                                chat_box.scrollTop = chat_box.scrollHeight;
                                // scroll the whole window
                                window.scrollTo(0, document.body.scrollHeight);
                            }
                            */
                        }
                    );
                }

            })
            .catch((error) => {
                console.error(dict_["Error add a new list"]+" "+this.props.list_name+":", error);
            });
    }

    handle_talking_delete(index){
        const json = {
            "action": "list",
            "ops": "delete",
            "list_name": this.props.list_name,
            "talking_id": this.state.talking_list[index].id,
            // "user_id": -1 // has to pass a value...not used at all
        };

        const formData = new URLSearchParams();
        for (const key in json) {
            formData.append(key, json[key]);
        }
        fetch("/action/", {
            method: "POST",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData // must stringify
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                console.log({ ...json, ...data });
                if (("ifsuccess" in data) && (data["ifsuccess"] == "true")) {
                    var talking_list = this.state.talking_list;
                    // use splice to remove
                    talking_list.splice(index, 1);
                    this.setState({ talking_list: talking_list });
                }

            })
            .catch((error) => {
                console.error("Error delete a list "+ this.props.list_name +":", error);
            });
    }

    // click its parent li...also effect
    // e is automatically passed although not mention in onClick={this.handle_talking_click_p.bind(this, index)}
    handle_talking_click_p(index,e){ 
        if (e.target === e.currentTarget) {
            // This is where you put your original click handling logic
            this.handle_talking_click(index,e); // only onclick automatically pass, not function
        }
    }

    handle_talking_click(index,e) {
        // here must use bind to define "this", otherwise, undefined
        // talking_active.bind(this, index)();

        // make input not work
        if (e.target.tagName === 'INPUT') 
            return;
        
        function talking_active(index){
            // active
            // alert(index);  //"__"+this.state.talking_list[index].id
            this.setState({
                talking_active_th: index,
                talking_id: this.state.talking_list[index].id
            },
                () => { // make sure happen after 
                    // this.chat_box_scroll_down();
                }
            );
        }


        var talking_id = this.state.talking_list[index].id;


        // other external action
        if ("ex_click" in this.props){
            this.props.ex_click(talking_id);
        } 
        // directly do it
        talking_active.bind(this, index)();
        /*
        if (talking_id in this.state.dialog_sum){ // already fetched, just show
            // active
            talking_active.bind(this, index)();
        } else { // not fetched, must first fetch it
            const json = {
                "action": "list",
                "ops": "load",
                "list_name": this.props.list_name,
                "talking_id": talking_id,
                // "user_id": -1 // has to pass a value...not used at all
            };

            const formData = new URLSearchParams();
            for (const key in json) {
                formData.append(key, json[key]);
            }
            fetch("/action/", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: formData // must stringify
            })
                .then((response) => response.json())
                .then((data) => {
                    // console.log(data);
                    console.log({ ...json, ...data });
                    if (("ifsuccess" in data) && (data["ifsuccess"] == "true")) {
                        // active
                        talking_active.bind(this, index)();
                    } else {
                        alert(data["message"])
                    }

                })
                .catch((error) => {
                    console.error("Error to fetch the dialog with a talking:", error);
                });
        }*/
    }

    handle_talking_edit_state(index){ // change to edit state....textbox
        this.setState({talking_edit_th: index});
    }

    handle_talking_edit(index){ // edit !
        this.edit_box.current.disabled = true;
        const json = {
            "action": "list",
            "ops": "update",
            "list_name": this.props.list_name,
            "talking_id": this.state.talking_list[index].id,
            "summary": this.edit_box.current.value,
        };

        const formData = new URLSearchParams();
        for (const key in json) {
            formData.append(key, json[key]);
        }
        fetch("/action/", {
            method: "POST",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData // must stringify
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                console.log({ ...json, ...data });
                if (("ifsuccess" in data) && (data["ifsuccess"] == "true")) {
                    var talking_list = this.state.talking_list;
                    // load dialog
                    var talking_list = this.state.talking_list;
                    talking_list[index]["summary"] = this.edit_box.current.value;
                    
                    // update state
                    this.setState({
                        talking_list: talking_list,
                        talking_edit_th: -1
                    });
                    this.edit_box.current.disabled = false;
                
                } else {
                    alert(data["message"]);
                    this.edit_box.current.disabled = false;
                }

            })
            .catch((error) => {
                console.error("Error to edit/rename the list "+this.props.list_name+": ", error);
                this.edit_box.current.disabled = false;
            });
        }

    render(){
        {/*Friend List */}
        {/*{this.state.number} <br /> {cache["status"]}*/}
        {/*must both heigh is less than 100vh, or still scroll down*/}
        return (
            <div className="friend-list" style={{overflowY: "auto", height:window.innerHeight-150}}>
                <ul className="list-group">
                    {this.state.talking_list.map((talking, index) => (
                        /*to make button align to the end:   
                        <li style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}/>
                            <button style={{ marginLeft: 'auto' }}/>
                        </li>*/
                        /*paddingTop: '2px', paddingBottom: '2px'  to reduce void click*/

                        <li
                        key={index}
                        className={this.state.talking_active_th == index ? "list-group-item active" : "list-group-item"} 
                        style={{ display: 'flex', alignItems: 'center' }}
                        onClick={this.handle_talking_click_p.bind(this, index)}>
                            {this.state.talking_edit_th == index ? 
                                    <span onClick={this.handle_talking_edit.bind(this, index)} className="glyphicon glyphicon-ok" title={dict_["OK"]}/>
                                    :
                                    <span onClick={this.handle_talking_edit_state.bind(this, index)} className="glyphicon glyphicon-pencil" title={dict_["edit"]}/>}
                                
                            <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}
                            onClick={this.handle_talking_click.bind(this, index)}>
                                {this.state.talking_edit_th == index ? 
                                    /*here must use defaultValue, if use value then unchangeable*/
                                    /* width:'100%' in style mean as big as allowed, not expanding*/
                                    <input type="text" style={{ fontSize: '16px', backgroundColor:'lightblue', width:'100%'}} defaultValue = {talking.summary} ref={this.edit_box}/>
                                    : 
                                    <span >{talking.summary} </span> }
                            </div>
                            <button type="button" className="btn btn-default" aria-label="Left Align" style={{ marginLeft: 'auto' }}>
                                <span onClick={this.handle_talking_delete.bind(this, index)} className="glyphicon glyphicon-minus" title={dict_["remove"]}>  </span>
                            </button>
                        </li>
                    ))}
                    
                    {// add option
                        <li
                        key={this.state.talking_list.length}
                        className={"list-group-item"}>
                        {dict_["add talking"]} 
                        <button type="button" className="btn btn-default" aria-label="Left Align">
                            <span onClick={this.handle_talking_add.bind(this, this.state.talking_list.length)} className="glyphicon glyphicon-plus" title={dict_["add"]}>  </span>
                        </button>
                        </li>
                    }
                    {/*<li className="list-group-item active">Friends</li>
                    <li className="list-group-item">Friend 1</li>
                    <li className="list-group-item">Friend 2</li>
                    <li className="list-group-item">Friend 3</li>
                    Add more friends as list items */}
                </ul>
            </div>
        );
    }
};

export default List;
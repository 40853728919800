import React from 'react';

class CompanyInfo extends React.Component {
  render() {
    const {companyName, location, street, contact, website } = this.props;

    return (
      <div className="company-info-container">
        <p><strong></strong> {companyName}</p>
        <p><strong></strong> {location}<span/> {street}</p>
        
        <p>  {contact} <span/><span/><span/> <a href={"https://"+website}>{website}</a> </p>
        <p><strong></strong> </p>
      </div>
    );
  }
}

export default CompanyInfo;

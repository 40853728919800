// display the email...currently only inbox
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Reply from './reply';

// language option
import {dict_} from '../language';

import Anemail from './anemail';

class Email extends Component {
	constructor(props) {
        super(props);

        /**/
        this.state = {
            mailbox_email_list: [{"name":"inbox","list":[]}], // mailbox-->email_list-->email
            // active
            active_mailbox_th: -1, 
            active_mailbox_name: 'inbox', 
            active_email_th: -1, // th 
            active_email_id: -1,
            
            // show list or email
            main: "list",
            send_signal: true, // for show send email
            
            dialog_sum: {}, // talking_id -- message 
            th_id: 0, // current active friend's th, default is 0 (first)
            talking_id: -1, // current active talking's id
            username: "", // current active friend's user name 
            self_id: -1, // his own id ?
        };


        this.chat_box = React.createRef();
        this.sending_message = React.createRef();
        this.sending_button = React.createRef();
        this.edit_box = React.createRef();

        this.sending = false; // state of sending
    }

    initial_setting() {
        const json = {
            "action": "email",
            "ops": "load_mailbox_email_list", // email_list
        };

        const formData = new URLSearchParams();
        for (const key in json) {
            formData.append(key, json[key]);
        }
        fetch("/action/", {
            method: "POST",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData // must stringify
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                console.log({ ...json, ...data });
                // must set to wait, otherwise this.state.mailbox_email_list.inbox  undefined
                if (("ifsuccess" in data) && (data["ifsuccess"] == "true")) {
                    this.setState({ mailbox_email_list: data.mailbox_email_list },
                    // console.log(data.mailbox_email_list.inbox) console.log(this.state.mailbox_email_list[0].email_list)
                    () => { }
                );
                }
            })
            .catch((error) => {
                console.error("Error fetch talking:", error);
            });
    }

    componentWillMount() {
        this.initial_setting();
    }


    handle_email_delete(index){
        const json = {
            "action": "email",
            "talking_id": this.state.email_list[index].id,
            "ops": "delete",
            // "user_id": -1 // has to pass a value...not used at all
        };

        const formData = new URLSearchParams();
        for (const key in json) {
            formData.append(key, json[key]);
        }
        fetch("/action/", {
            method: "POST",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData // must stringify
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                console.log({ ...json, ...data });
                if (("ifsuccess" in data) && (data["ifsuccess"] == "true")) {
                    var email_list = this.state.email_list;
                    // use splice to remove
                    email_list.splice(index, 1);
                    this.setState({ email_list: email_list });
                }

            })
            .catch((error) => {
                console.error("Error delete a talking:", error);
            });
    }

    chat_box_scroll_down() {
        if (this.chat_box.current) {
            // scroll the chat
            const chat_box = this.chat_box.current;
            chat_box.scrollTop = chat_box.scrollHeight;
            // scroll the whole window
            window.scrollTo(0, document.body.scrollHeight);
        }
    }




    handle_mailbox_click(index) {
        // here must use bind to define "this", otherwise, undefined
        // talking_active.bind(this, index)();
        function mailbox_active(index){
            // active
            // alert(index);  //"__"+this.state.email_list[index].id
            this.setState({
                active_mailbox_th: index,
                main: "list"
            },
                () => { // make sure happen after 
                    this.chat_box_scroll_down();
                }
            );
        }


        if ("list" in this.state.mailbox_email_list[index]){
            // already has the email list
            mailbox_active.bind(this, index)();
        } else { // not fetched, must first fetch it
            const json = {
                "action": "email",
                "mailbox_name": this.state.mailbox_email_list[index].name,
                "ops": "load_email_list"
            };
    
            const formData = new URLSearchParams();
            for (const key in json) {
                formData.append(key, json[key]);
            }
            fetch("/action/", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: formData // must stringify
            })
                .then((response) => response.json())
                .then((data) => {
                    // console.log(data);
                    console.log({ ...json, ...data });
                    /*
                    if (("ifsuccess" in data) && (data["ifsuccess"] == "true")) {
                        var email_list = this.state.email_list;
                        // load dialog
                        var dialog_sum = this.state.dialog_sum;
                        dialog_sum[talking_id] = data["dialog_list"];
                        
                        // add dialog list
                        this.setState({dialog_sum: dialog_sum});
                        // active
                        mailbox_active.bind(this, index)();
                    } else {
                        alert(data["message"])
                    }
                    */
    
                })
                .catch((error) => {
                    console.error("Error to fetch the dialog with a talking:", error);
                });
        }
    }


    list_show(){
        const cellStyle = {
            borderBottom: '1px solid black',
            padding: '8px',
            textAlign: 'left'
          };

        var list_;
        // this will make it wait and is enough good like gmail !!!!!!!!!!
        if (this.state.active_mailbox_th>=0 && this.state.active_mailbox_th<this.state.mailbox_email_list.length){
            list_ = <div className="friend-list">
                        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                            {/*<thead>
                                <tr>
                                <th>Name</th>
                                <th>Age</th>
                                <th>City</th>
                                </tr>
                            </thead>*/}
                            <tbody>
                                {this.state.mailbox_email_list[this.state.active_mailbox_th].list.map((email, index) => (
                                <tr key={index} onClick={this.handle_email_click.bind(this,index)}>
                                    <td style={cellStyle}>{email.Subject}</td>
                                    <td style={cellStyle}>{email.From}</td>
                                    <td style={cellStyle}>{email.Date}</td>
                                    <td style={cellStyle}>{email.email_id}</td>
                                </tr>))}
                            </tbody>
                        </table>
                    </div>;
        } else {
            list_ = <div></div>
        }
        return(list_);
    }

    handle_email_click(index){
        this.setState({main: "email", 
                        active_email_th: index,
                        reply: false});
        // maybe some email is still not loaded totally ??????
    }

    handle_back_list(){
        this.setState({main: "list"});
    }


    handle_email_write(){
        this.setState({send_signal: !this.state.send_signal,
                        main: "write"});
    }


    render() {
        var email;
        if (this.state.active_email_th >= 0) {
            email = this.state.mailbox_email_list[this.state.active_mailbox_th].list[this.state.active_email_th];
        } else
            email = {};

        var main;
        if (this.state.main === "list"){
            main = this.list_show()
        } else if (this.state.main === "email"){
            main = <div> 
                        <div>
                            <span className="glyphicon glyphicon-menu-left navbar-icon" onClick={this.handle_back_list.bind(this)} style={{color: 'black',fontSize: '18px'}} title={dict_["back"]}></span>
                        </div>
                        <Anemail email={email}/>
                    </div>;
        } else if (this.state.main === "write"){
            // console.log(this.state.main);
            main = <Reply content={""} reply_signal={this.state.send_signal}/>;
        }

        return (
            <div className="container" style = {{width: "100%"}}>
                <div className="row" style = {{width: "100%"}}>
                    <div className="col-md-2">
                        {/*Friend List */}
                        {/*{this.state.number} <br /> {cache["status"]}*/}
                        <div className="friend-list">
                            <div className="list-group">
                                {this.state.mailbox_email_list.map((mailbox, index) => (
                                    <a
                                    key={index}
                                    href={"#"}
                                    onClick={this.handle_mailbox_click.bind(this, index)}
                                    className={this.state.active_mailbox_th == index ? "list-group-item active" : "list-group-item"} >
                                        <span>{mailbox.name} </span> 
                                    </a>
                                ))}
                                
                                {// add option
                                    <a
                                    key={this.state.mailbox_email_list.length}
                                    className={"list-group-item"}
                                    href={"#"}
                                    onClick={this.handle_email_write.bind(this)}
                                    >
                                    {dict_["write a new email"]} 
                                    <button type="button" className="btn btn-default" aria-label="Left Align">
                                        <span  className="glyphicon glyphicon-plus" title={dict_["write a new email"]}>  </span>
                                    </button>
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10">
                        {main}
                    </div>
                </div>
            </div>
        )
    }
}


export default Email;


import React, { Component } from 'react';

// language option
import {dict_} from '../language';

class Reply extends Component {
	constructor(props) {
        super(props); // just initialize

        // this.setState(); // {...props});
        // can't use setState, must use this.state= {}
        this.state={reply: true};
        // !!!!!!!!!!!!!! can't set show it here, but in parent
        // !!!!!!!!!!!!! use a props that change alternatively to pass the signal(not constant props)

        // for reply
        this.reply_box = React.createRef();

        // for information
        this.Subject= React.createRef();
        this.To = React.createRef();
        // this.from = React.createRef();
    }

    componentDidMount(){
        // every time click --> Did update, not didmount(only first time)
        // the order is very tricky !!!!!!!!!!!!!!!!!!!!!!
    }

    componentDidUpdate(preProps){
        // !!!!!!!! in componentDidUpdate, must compare this.props.content != preProps.content, or update again and again
        // !!!!!!!! can't use this.setState(this.props), but this.setState({...this.props})
        // !!!!!!!! or more specificly use {content: this.props.content}
        // !!!!!!!! no need to set state at all, directly use value={this.props.content} in render(), also update automatically....not viable, value can't change any more
        // !!!!!!!! <textarea value={this.props.content} />
        // this.handle_textarea_change(); // here if I use, no event for me to use 
        // console.log(nextProps.content);
        // if (this.props.content != preProps)
        /*
        if (this.props.content != preProps.content)
        this.setState({...this.props},()=>{
            // this.reply_box.current.onChange();  // can't use it, not a function
            const event = new Event('input', { bubbles: true });
            if (this.reply_box.current) {
                this.reply_box.current.dispatchEvent(event);
            }
        });
        */

        // !!!!!!!!!!!!!!!!!!!!! here different from current value, update, not from preprops
        if (this.props.reply_signal != preProps.reply_signal){
            this.setState({reply:true},()=>{
                if (this.props.email){
                    let email = this.props.email;
                    this.Subject.current.value = "Re: " + email.Subject;
                    // this.from.current.value = email.To;
                    this.To.current.value = email.From;
                }
            });
        }


        // !!!!!!!!!!!!!!!!!!!!! here this.reply_box.current might not exist at all
        if ((this.reply_box.current) && (this.reply_box.current.value != this.props.content)){ //preProps.content){
            this.reply_box.current.value = this.props.content;
            // console.log("change content !");
            this.handle_textarea_change();
        }
    }

    /*
    
    */


    handle_email_send(){
        // var email_info = {...email};
        // email_info["parts"] = null;

        const json = {
            "action": "email",
            "ops": "email_send",
            // "from": this.from.current.value,
            "To": this.To.current.value,
            "Subject": (this.props.email)?(this.props.email['Subject']):(this.Subject.current.value),
            "msg_id": (this.props.email)?(this.props.email['Message-ID']):(null),
            "threadId": (this.props.email)?(this.props.email['threadId']):(null),
            "body": this.reply_box.current.value
        };


        console.log(this.props.email);
        const res = (this.props.email)?(this.props.email['Message-ID']):(null)
        console.log(res, typeof(res), res===null, res==="null");
        /**/
        const formData = new URLSearchParams();
        for (const key in json) {
            formData.append(key, json[key]);
        }
        fetch("/action/", {
            method: "POST",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData // must stringifyJSON.stringify(json) //
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                console.log({ ...json, ...data });
                if (data["ifsuccess"] == "true")
                    this.setState({reply: false}, ()=>{
                        // alert("send successful");
                    });
            })
            .catch((error) => {
                console.error("Error to fetch the dialog with a talking:", error);
            });
    }

    handle_textarea_change(){
        var reply_box = this.reply_box.current;
        // !!!!!!!!!!!!!!!!!!!!! remove this, otherwise empty""not work
        // if (true || reply_box.value) {
            reply_box.style.height = 'auto'; // Reset the height to auto to recalculate the scrollHeight
            reply_box.style.height = `${reply_box.scrollHeight+6}px`; // Set the height to match the content
            // console.log("size !");
        // }
    }

    render() {
        const cellStyle = {
            borderBottom: '1px solid black',
            padding: '8px',
            textAlign: 'left'
          };

        var reply_;
        if (this.state.reply){
            reply_ = <div style={{ width: '100%' }}>
                        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                            <tbody>
                                <tr key={0}>
                                    <td style={cellStyle}>{"Subject"}</td>
                                    <td style={cellStyle}><input type="text" ref={this.Subject}/></td>
                                </tr>
                                {/*<tr key={1}>
                                    <td style={cellStyle}>{"from"}</td>
                                    <td style={cellStyle}><input type="text" ref={this.from}/></td>
                                </tr>*/}
                                <tr key={2}>
                                    <td style={cellStyle}>{"To"}</td>
                                    <td style={cellStyle}><input type="text" ref={this.To}/></td>
                                </tr>
                            </tbody>
                        </table>
                        <div style={{ textAlign: 'right' }}>
                            <button onClick={this.handle_email_send.bind(this,this.props.email)}>send</button>
                        </div>
                        <textarea style={{ width: '100%', height:'auto' }} ref={this.reply_box}  onChange={this.handle_textarea_change.bind(this)}/>
                    </div>;
        }else{ 
            reply_ = <div></div>;
        }

        return (
            // !!!!!!!!!!!!!!!!!! here must with <div> out layer, or it is not a valid JSX
            <div>
                {reply_}
            </div>
        );
    }
}


export default Reply;
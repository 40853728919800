import React, { Component } from 'react';
// documentation:  https://lucasbassetti.com.br/react-simple-chatbot/#/docs/installation
// React Simple Chatbot is no longer maintained. I recommend using react-chatbotify as an alternative.
// https://github.com/tjtanjin/react-chatbotify?tab=readme-ov-file#documentation
import ChatBot from 'react-simple-chatbot';
import styled from 'styled-components';




class CustomComponent extends Component {
  componentDidMount() {
    const { steps, triggerNextStep } = this.props;
    const userInput = steps.userInput.value;
    const chatHistory = this.getChatHistory(steps);
    // Trigger your function or action with the user input
    // this.myFunction(userInput, updateSteps);
    
    const response = `You said: ${userInput} \n\n ${JSON.stringify(chatHistory)}`;
    // Update the steps dynamically to include the response
    // updateSteps(response);

    // Automatically proceed to the next step
    triggerNextStep({ value: response });
  }

  getChatHistory(steps) {
    return Object.values(steps).map(step => ({
      id: step.id,
      message: step.message,
      user: step.user
    }));
  }

  myFunction(input) {
    console.log("User input:", input);
    // Your custom function or action goes here
    // For example, you could make an API call, update state, etc.
    const response = `You said: ${input}`;
    // Update the steps dynamically to include the response
    // updateSteps(response);
  }

  render() {
    return (
      <div>
        <input type="text"></input>
        <p>Processing your input...</p>
      </div>
    );
  }
}





// in index, must not put chatbox inside StrictMode...otherwise doubled message
class Chatbox extends Component{

	//initially, no submission errors
	constructor(props) {
        super(props);

        // message can't automatically turn to another line, missing character
        this.state = { steps:[
            {
              id: '0',
              message: 'Welcome to the chatbot! How can I help you today?',
              trigger: '1',
            },
            {
              id: '1',
              message: 'What is your name?',
              trigger: 'userInput',
            },
            {
              id: 'userInput',
              user: true,
              trigger: 'gender',
            },
            {
              id: 'gender',
              options: [
                { value: 'male', label: 'Male dfasfdsafsdsafdsfsfasfdsafadsfsafdsafs', trigger: '3' },
                { value: 'female', label: 'Female fdafsdafdsafdsafsafasfasfd', trigger: '3' },
              ],
            },
            {
              id: '3',
              message: 'Hi {previousValue}! Nice to meet you.',
              // end: true,
              trigger: 'processInput',
            },
            {
              id: 'processInput',
              component: <CustomComponent/>,
              waitAction: true,
              trigger: ({ value }) => 'done',
            },
            {
              id: 'done',
              message: ({ previousValue }) => previousValue, //'Thank you for providing your name!',
              trigger: '1',
              // end: true,
            },
          ]};
    }


	

	render(){
    {/*
    // embedded page is not allowed by many government website
    // but we can generate the link, or open it in new window automatically
    <iframe src="https://www.sandiego.gov/" title="description"></iframe> 
    */}


      var ChatBotBubble = 
      <button onClick={()=>{this.setState({isOpen: !this.state.isOpen})}}
      style={{backgroundColor: "#00b5ad",
              color: "#fff",
              border: "none",
              borderRadius: "50%",
              width: "60px",
              height: "60px",
              fontSize: "18px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)"}}
      >Chat</button>;
      
      
      var ifphone = window.innerWidth<500;

      // Z-index controls the stacking order
      return(
        <div>
            {this.state.isOpen?(
            <div style={{position: "fixed",
                        bottom: "10px",
                        right: "10px",
                        top: "10px",
                        left: "10px",
                        zIndex: "1000"}}>
                  
                  {/*must be a single inside ()*/}
                  
                    <div>
                      {ChatBotBubble}
                      <ChatBot
                      style={{
                        height: "100%",
                        width: "100%"}}
                      steps={this.state.steps}
                      headerTitle="ChatAssistant"
                      />
                    </div>
            </div>):
            (<div style={(ifphone)?({position: "fixed",
                      top: "10px",
                      left: "10px",
                      zIndex: "1000"})
                      :
                      ({position: "fixed",
                      bottom: "10px",
                      right: "10px",
                      zIndex: "1000"})}>
                {ChatBotBubble}
            </div>)}
          </div>
      );
	}
};

export default Chatbox;
import React, { Component } from 'react';
import {dict_} from './language';

import {VoiceRecognition} from './voice.js';


class Comm extends Component{

    constructor(props) {
        super(props);

        this.state = {
            // keypress
            multi_key: false,

            // message row
            // sending_message_rows: 1,
        };


        this.sending_message = React.createRef();
        this.sending_button = React.createRef();

    
        this.sending = false; // state of sending
    }


    handleKeyPress(e) {
        if (!this.state.ifphone){
            // several keyPress at the same time must be tracked step by step.
            // no same time, but order !
            if (e.key == 'Enter' && (!this.state.multi_key)) {
                this.handle_sending();
            } else if (e.key == 'Shift'){
                this.setState({multi_key: 'Shift'});
            }
        }
    }

    handleKeyUp(e) {
        if (!this.state.ifphone){
            // release itself
            if (e.key == this.state.multi_key)
                this.setState({multi_key: false});
        }
    }

    message_size_change(lines){
        // this.setState({sending_message_rows:lines});
        // report to parent
        
    }

    handletextChange(e){
        var lines = e.target.value.split('\n').length;
        if (lines>3){
            lines = 3;
        }  
        this.props.size_change(lines);
    }


    handle_sending() {
        // e.target.value
        // send the message
        if (!this.sending){
            this.props.handle_sending(this.sending_message.current.value);
        }
    }

    message_set = (message) => {
        this.sending_message.current.value = message;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.ifrun != this.props.ifrun){
            if (this.props.ifrun){
                // emptify
                this.sending_message.current.value = "";
                // re-enable
                this.sending = false;
                this.sending_button.current.disabled = false;
                this.sending_message.current.disabled = false;
                this.props.size_change(1);
            } else {
                // disable it
                this.sending = true;
                this.sending_button.current.disabled = true;
                this.sending_message.current.disabled = true;
            }
        }
    }


    render(){
        /* to make button align to the end:   
        <li style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}/>
            <button style={{ marginLeft: 'auto' }}/>
        </li>
        */
        /* to make its children stack vertically and each align in the center 
        alignItems: 'center' seem to be default, but has to work with display, flexDirection
        <div style={{display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'}}>
        */ 
        {/* Repeat similar structure for other messages */}
        return(
            <div className="chat-input" style={{ display: 'flex', alignItems: 'center' }}>
            {/*to avoid automatic magnitude:  <input type="text" style={{ fontSize: '16px' }}*/}
            {/*input can only be 1 row ? */}
            {this.props.ifphone?
            (<textarea style={{ fontSize: '16px' }} ref={this.sending_message} className="form-control" placeholder={dict_["type in the message here"]} 
                    // unstable in iphone, use fixed 3 rows 
                    rows={this.props.sending_message_rows}
                    // also need to report in case change size
                    onChange={this.handletextChange.bind(this)} />):
            (<textarea style={{ fontSize: '16px' }} ref={this.sending_message} className="form-control" placeholder={dict_["type in the message here"]} 
                    onKeyDown={this.handleKeyPress.bind(this)} 
                    onKeyUp={this.handleKeyUp.bind(this)} 
                    onChange={this.handletextChange.bind(this)}  
            rows={this.props.sending_message_rows}/>) }
            <div style={{marginLeft: 'auto', display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'}}>
                <VoiceRecognition style={{width:"28px", height:"28px"}} message_set={this.message_set}/>
                {/*<button className="btn btn-primary" ref={this.sending_button} onClick={this.handle_sending.bind(this)}>{dict_["send"]} </button>*/}
                <button className="glyphicon glyphicon-send" ref={this.sending_button} onClick={this.handle_sending.bind(this)} 
                style={{width:"38px", height:"38px"}}></button>
            </div>
        </div>
        );
    }
};

export default Comm;










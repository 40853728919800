// import { maxHeight } from '@mui/system';
// import { fireEvent } from '@testing-library/react';
import React, { Component } from 'react';
// import { Link, redirect } from 'react-router-dom';
// import Imgc from '../imgc';

// language option
import {dict_} from '../language';

import List from '../list.js';
import Dialog from '../dialog.js';
import Comm from '../comm.js';



// must put import ahead
// let cache = { status: "Good", friend_active_th: -1 };

class Talking extends Component {
    constructor(props) {
        super(props);

        /**/
        this.state = {
            dialog_sum: {}, // talking_id -- message 
            talking_id: -1, // current active talking's id
            self_id: -1, // his own id ?

            // showing model
            main: "list",
            
            // message row
            sending_message_rows: 1,
            message_ifrun: true,

            ifphone: false
        };


        this.chat_box = React.createRef();
    }

    // evoked by props change
    componentDidUpdate(prevProps){
        // Check if specific props have changed
        if (this.props.list_name !== prevProps.list_name) {
            // force to re-render
            window.location.reload();
        }
    }



    handle_sending(message) {
        if (this.state.talking_id!=-1){
            this.setState({message_ifrun:false});
            const json = {
                "action": "dialog",
                "ops": "add",
                "human": true,
                // list for checking
                "list_name": this.props.list_name,
                "list_id": this.state.talking_id, 
                // dialog for content
                "dialog_name": this.props.dialog_name,
                "content": message
            };

            const formData = new URLSearchParams();
            for (const key in json) {
                formData.append(key, json[key]);
            }
            fetch("/action/", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: formData // must stringify
            })
                .then((response) => response.json())
                .then((data) => {
                    // console.log(data);
                    console.log({ ...json, ...data });
                    if (("ifsuccess" in data) && (data["ifsuccess"] == "true")) {
                        // update UI
                        var dialog_list = this.state.dialog_sum[this.state.talking_id];
                        // add to the list, human, ai reponse, respectively
                        console.log(this.state.talking_id);                 
                        dialog_list.push(json);
                        dialog_list.push(data)
                        var dialog_sum = this.state.dialog_sum;
                        dialog_sum[this.state.talking_id] = dialog_list;

                        this.setState({dialog_sum: dialog_sum,
                            message_ifrun:true},
                            () => { // scroll_down 
                                this.chat_box_scroll_down();
                            });
                    } else{
                        alert(data["message"]);
                    }
                })
                .catch((error) => {
                    console.error(dict_["Error sending talking: "], error);
                });
        } else if (this.sending){

        } else if (this.state.talking_id==-1){
            alert(dict_["please choose a talking"]);
        } else {
            alert(dict_["unexpected error in sending talking"]);
        }
    }

    size_change(lines){
        this.setState({sending_message_rows:lines});
    }


    chat_box_scroll_down() {
        if (this.chat_box.current) {
            // scroll the chat
            const chat_box = this.chat_box.current;
            chat_box.scrollTop = chat_box.scrollHeight;
            // scroll the whole window
            window.scrollTo(0, document.body.scrollHeight);
        }
    }

    // click to load the dialog
    ex_click = (talking_id)=>{
        if (talking_id in this.state.dialog_sum){ // already fetched, just show
            // active
            // talking_active.bind(this, index)();
            this.setState({main: "dialog", 
                    talking_id: talking_id}, 
                () => { // make sure happen after 
                this.chat_box_scroll_down();
            });
        } else { // not fetched, must first fetch it
            const json = {
                "action": "dialog",
                "ops": "load",
                // list for checking
                "list_name": this.props.list_name,
                "list_id": talking_id,
                // dialog for content
                "dialog_name": this.props.dialog_name,
                // "user_id": -1 // has to pass a value...not used at all
            };
            console.log(json);
    
            const formData = new URLSearchParams();
            for (const key in json) {
                formData.append(key, json[key]);
            }
            fetch("/action/", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: formData // must stringify
            })
                .then((response) => response.json())
                .then((data) => {
                    // console.log(data);
                    console.log({ ...json, ...data });
                    if (("ifsuccess" in data) && (data["ifsuccess"] == "true")) {
                        // load dialog
                        var dialog_sum = this.state.dialog_sum;
                        dialog_sum[talking_id] = data["dialog_list"];
                        
                        // add dialog list, set dialog
                        this.setState({dialog_sum: dialog_sum,
                            main: "dialog", 
                            talking_id: talking_id}, 
                            () => { // make sure happen after 
                            this.chat_box_scroll_down();
                        });
                    } else {
                        alert(data["message"])
                    }
    
                })
                .catch((error) => {
                    console.error("Error to fetch the dialog with a talking:", error);
                });
        }
    }




    handle_back_list(){
        this.setState({main: "list"});
    }


    // re-render when resize
    updateDimensions = () => {
        this.setState({
          ifphone: window.innerWidth<500
        });
        this.chat_box_scroll_down();
    };
    componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
    }

    render() {
        // use chat gpt is even more convenient than forked from github...more flexible to change
        // different align:
        /*
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', borderBottom: '1px solid gray' }}>
        <div>Contact Details</div>
        <button type="button" className="edit_button">
            My Button
        </button>
        </div>

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderBottom: '1px solid gray' }}>
        <div>Contact Details</div>
        <button type="button" className="edit_button">
            My Button
        </button>
        </div>
        
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', borderBottom: '1px solid gray' }}>
        <div>Contact Details</div>
        <button type="button" className="edit_button">
            My Button
        </button>
        </div>
        */

        var ifphone = window.innerWidth<500;
        var sending_message_rows = (ifphone)?(3):(this.state.sending_message_rows);

        var chat_list;
        if (this.state.talking_id >= 0) {
            chat_list = this.state.dialog_sum[this.state.talking_id];
            // alert("messages_"+this.state.user_id);
        } else
            chat_list = [];

        var show_list=
            <div className="col-md-3">
            <List list_name={this.props.list_name} ex_click={this.ex_click.bind(this)}/>
            </div>;

        var show_dialog=
            (ifphone)?(
                <div className="col-md-9">
                    <Comm handle_sending={this.handle_sending.bind(this)} 
                            size_change={this.size_change.bind(this)} 
                            sending_message_rows={sending_message_rows}
                            ifphone={ifphone} ifrun={this.state.message_ifrun}/>
                    <Dialog chat_list={chat_list} ifphone={ifphone} chat_box={this.chat_box} height={window.innerHeight-115-((ifphone)?(20):(0))-(sending_message_rows-1)*22}/>
                </div>
            ):(
            <div className="col-md-9">
                <Dialog chat_list={chat_list} ifphone={ifphone} chat_box={this.chat_box} height={window.innerHeight-115-((ifphone)?(20):(0))-Math.max((sending_message_rows-1)*22,35)} sending_message_rows={sending_message_rows}/>
                <Comm handle_sending={this.handle_sending.bind(this)} 
                        size_change={this.size_change.bind(this)} 
                        sending_message_rows={sending_message_rows}
                        ifphone={ifphone} ifrun={this.state.message_ifrun}/>
            </div>);

        var res;
        if (ifphone){ // on phone
            if (this.state.main=="list"){
                res = show_list;
            }else if (this.state.main=="dialog"){
                res = <div> 
                            <div>
                                <span className="glyphicon glyphicon-menu-left navbar-icon" onClick={this.handle_back_list.bind(this)} style={{color: 'black',fontSize: '18px'}} title={dict_["back"]}></span>
                            </div>
                            {show_dialog}
                      </div>;
            }
        } else { // on computer
            res = <div> 
                    {show_list}
                    {show_dialog}
                </div>;
        }

        return (
            <div className="container">
                <div className="row">
                    {res}
                </div>
            </div>
        )
    }
}

export default Talking;




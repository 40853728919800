import React, { Component } from 'react';

class MarkdownRenderer extends Component {
  renderMarkdown(markdown) {
    // Split the markdown into lines
    const lines = markdown.split('\n');
    
    // Process each line
    return lines.map((line, index) => {
      // Headers
      if (line.startsWith('#')) {
        const level = line.match(/^#+/)[0].length;
        const text = line.replace(/^#+\s/, '');
        return React.createElement(`h${level}`, { key: index }, text);
      }
      
      // Bold
      line = line.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
      
      // Italic
      line = line.replace(/\*(.*?)\*/g, '<em>$1</em>');
      
      // Inline Code
      line = line.replace(/`(.*?)`/g, '<code>$1</code>');
      
      // Unordered List
      if (line.startsWith('- ')) {
        return <li key={index} dangerouslySetInnerHTML={{ __html: line.slice(2) }} />;
      }
      
      // Ordered List
      if (/^\d+\.\s/.test(line)) {
        return <li key={index} dangerouslySetInnerHTML={{ __html: line.replace(/^\d+\.\s/, '') }} />;
      }
      
      // Paragraphs
      return <p key={index} dangerouslySetInnerHTML={{ __html: line }} />;
    });
  }

  render() {
    const { markdown } = this.props;
    return <div className="markdown-content">{this.renderMarkdown(markdown)}</div>;
  }
}

export default MarkdownRenderer;


import React, { Component } from 'react';
import { Link, Outlet } from 'react-router-dom';

class UpdatePassword extends Component{
	//initially, no submission errors
	constructor(props) {
        super(props);

        this.state = {hasError: false, succeeded: false, errorMsg: "", successMsg: ""};

		this.new_password_confirmation = React.createRef();
		this.new_password = React.createRef();
    }

	handleUpdatePassword(){
		/*
		if(true){
			var new_password = this.refs.new_password.value;
			var new_password_confirmation = this.refs.new_password_confirmation.value;
			var that = this;

			if(new_password && new_password_confirmation && new_password == new_password_confirmation){
				
				var user = firebase.auth().currentUser;
				user.updatePassword(new_password).then(function(){
					hashHistory.push('/');
				}, function(error){
					that.setState({hasError: true});
					that.setState({errorMsg: "An error occured!"});
				});
			}else{

				that.setState({hasError: true});
				that.setState({errorMsg: "Passwords do not match."});
			}
			this.refs.new_password.value = "";
			this.refs.new_password_confirmation.value = "";
		}
		*/

		if(this.new_password_confirmation.current.value == this.new_password.current.value){
			const json = {
				"action": "account",
				"ops": "change_psd" ,
				"typed_password": this.new_password.current.value
			};

			const formData = new URLSearchParams();
			for (const key in json) {
				formData.append(key, json[key]);
			}
			fetch("/action/", {
				method: "POST",
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				body: formData // must stringify
			})
				.then((response) => response.json())
				.then((data) => {
					// console.log(data);
					console.log({ ...json, ...data });
					if (("ifsuccess" in data) && (data["ifsuccess"] == "true")) {
						window.location.assign('/');
					} else{
						this.setState({hasError: true});
						this.setState({errorMsg: "An error occured!"});
					}
				})
				.catch((error) => {
					console.error("Error account change password:", error);
				});

		} else {
			this.setState({hasError: true});
			this.setState({errorMsg: "Passwords do not match."});
		}
	}

	//creates a div alert-danger with the error message
	errorMessage(){
		return <div className="alert alert-danger"><strong>Error! </strong>{this.state.errorMsg}</div>;
	}

	//creates an empty div if no error message
	noErrorMessage(){
		return <div></div>;
	}

	//if "Enter" was pressed, act as Sign Up was clicked
	handleKeyPress(e){
		if(e.key == 'Enter'){
			try{
				this.handleUpdatePassword();
			}
			catch(e){};
		}
	}

	render(){
		//gets the appropriate error alert div depending on whether or not the form has an error
		var errorAlert;
		if(this.state.hasError){
			errorAlert = this.errorMessage();
		}else{
			errorAlert = this.noErrorMessage();
		}

		return (
			<div>
				{errorAlert}
				<input type="password" ref={this.new_password} placeholder="新密码" className="form-control" onKeyDown={this.handleKeyPress.bind(this)}/><br />
				<input type="password" ref={this.new_password_confirmation} placeholder="确认密码" className="form-control" onKeyDown={this.handleKeyPress.bind(this)}/><br />
						
				<button onClick={this.handleUpdatePassword.bind(this)} className="btn btn-primary">更新密码 </button><br />
			</div>
		);
	}
};

export default UpdatePassword;
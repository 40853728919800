import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';
// for component, can't use useNavigate

import { restapi, restapi_2 } from '../action';

// language option
import {dict_} from '../language';

class SessionUser extends Component {

	//initially, there are no submission errors
	constructor(props) {
		super(props);

		this.email = React.createRef();
		this.password = React.createRef();
		// must use bind, otherwise can't use....
		// here is not needed
		// this.handleLogIn = this.handleLogIn.bind(null);
		// must do it in onClick={this.handleLogIn.bind(this)} // otherwise, can't find it

		this.state = { hasError: false, isLoged: false };

		this.change_state = this.change_state.bind(this);
	}

	// change the state, according to the response json
	change_state(json) {
		if ("action" in json) { // this return from a post request
			switch (json["action"]) {
				case "login":
					this.setState({
						hasError: !json["ifsuccess"],
						isLoged: json["ifsuccess"]
					});

					break;
			}
		}
	}

	//logs the user in with the firebase method and reroutes to the home page
	handleLogIn() {

		// var that = this;
		const email = this.email.current.value;
		const password = this.password.current.value;

		// Your login logic goes here
		// console.log(email);

		/*
		firebase.auth().signInWithEmailAndPassword(email, password).catch(function(error) {
				var errorCode = error.code;
				var errorMessage = error.message;

				//sets hasError and the errorMsg if an error occured to show in the alerts
				if(error){
					that.setState({hasError: true});
					that.setState({errorMsg: "Invalid email or password combination."});
				}
		});

		//if successfully logged in, reroute to home
		this.unsubscribe = firebase.auth().onAuthStateChanged((user) => {
				if (user) {
				hashHistory.push("/");
				} else {
				hashHistory.push("/login");
				}
		});
		*/

		function check_login(change_state) {
			var json = {
				"action": "login",
				"username": email,
				"password": password
			};

			restapi(json, change_state.bind(null));
		}

		check_login(this.change_state);

	}

	componentWillUnmount() {
		if (typeof this.unsubscribe == 'function') {
			this.unsubscribe();
		}
	}

	//if user pressed "Enter" while filling out his/her info, act as if Login was clicked
	handleKeyPress(e) {
		if (e.key == 'Enter') {
			this.handleLogIn();
		}
	}

	//creates a div alert-danger with the error message
	errorMessage() {
		return <div className="alert alert-danger"><strong>{dict_["Error!"]} </strong>{this.state.errorMsg}</div>;
	}

	//creates an empty div if no error message
	noErrorMessage() {
		return <div></div>;
	}

	

	render() {
		//gets the appropriate error alert div depending on whether or not the form has an error
		var errorAlert;
		if (this.state.hasError) {
			errorAlert = this.errorMessage();
		} else {
			errorAlert = this.noErrorMessage();
		}


		if (!this.state.isLoged) {
			return (

				<div>
					{errorAlert}
					<div className="col-md-4">
					</div>

					<div className="col-md-4">
						<center>
							<h1 className="margin-top-30">{dict_["log in"]}</h1><br />

							<input type="text" ref={this.email} placeholder={dict_["email or phone number"]} className="form-control" onKeyDown={this.handleKeyPress.bind(this)} /><br />
							<input type="password" ref={this.password} placeholder={dict_["password"]} className="form-control" onKeyDown={this.handleKeyPress.bind(this)} /><br />
							<button className="btn btn-primary margin-bottom-10" onClick={this.handleLogIn.bind(this)}>{dict_["log in"]}</button><br />
							{dict_["no account yet ?"]} <Link to="/signup">{dict_["sign up !"]}</Link>
						</center>
					</div>

					<div className="col-md-4">
					</div>

				</div>
			);
		} else {
			// here refresh to make the Layout also reload
			window.location.assign('/'); // can't use reload, otherwise, url might unchanged.
			return (
				<Navigate to="/" />
			);
		}
	}
}

export default SessionUser;


function restapi(json, func) { // fun
    var xhr = new XMLHttpRequest();
    var url = "/action/"; // when change this, we need to refresh the page
    // when deploy, this must be chagne to relative
    // use jsp to debug dynamically, apache http server has to reload !!
    xhr.open("POST", url, true);
    console.log("url__\n", url);
    xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xhr.onreadystatechange = function() {
        if (xhr.readyState == 4 && xhr.status == 200) { // OK status
            console.log(xhr.responseText);
            var json_response = JSON.parse(xhr.responseText);
            // json_response["action"] = json["action"];
            json_response = {...json, ...json_response}; // 2nd will overwrite
            if ("message" in json_response)
                alert(json_response["message"]);
            if (json_response["ifsuccess"] === "true") {
                // alert(json["type"] + " succeed in " + json["table_name"]);
                func(json_response); // maybe change the login form
            } else if (json_response["ifsuccess"] === "false") {} else {
                alert("unexpected ifsuccess = " + json_response["ifsuccess"]);
            }
        } else if (xhr.status == 500) {
            alert("jsp error in server side, please contact the administer");
        } else if (xhr.status == 200) {
            // nothing 
        } else if (xhr.status == 404) {
            alert("link error"); // not found...like when use 3000 port directly
        } else if (xhr.status == 0) {
            // alert("link error");
        } else {
            alert("unhandled status " + xhr.status);
        }
    }

    var params = "";
    var keys = Object.keys(json);
    for (var i = 0; i < keys.length - 1; i++)
        params += keys[i] + "=" + json[keys[i]] + "&";
    params += keys[i] + "=" + json[keys[i]];
    console.log("send\n" + params);
    xhr.send(params);

}




function restapi_2(json, func_t, func_f) { // fun
    var xhr = new XMLHttpRequest();
    var url = "/action/"; // when change this, we need to refresh the page
    // when deploy, this must be chagne to relative
    // use jsp to debug dynamically, apache http server has to reload !!
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xhr.onreadystatechange = function() {
        if (xhr.readyState == 4 && xhr.status == 200) { // OK status
            console.log(xhr.responseText);
            var json_response = JSON.parse(xhr.responseText);
            // json_response["action"] = json["action"];
            json_response = {...json, ...json_response};
            console.log(json_response);
            if ("message" in json_response)
                alert(json_response["message"]);
            if (json_response["ifsuccess"] === "true") {
                // alert(json["type"] + " succeed in " + json["table_name"]);
                func_t(json_response); // maybe change the login form
            } else if (json_response["ifsuccess"] === "false") {
                func_f();
            } else {
                alert("unexpected ifsuccess = " + json_response["ifsuccess"]);
            }
        } else if (xhr.status == 500) {
            alert("jsp error in server side, please contact the administer");
        } else if (xhr.status == 200) {
            // nothing 
        } else if (xhr.status == 404) {
            alert("link error");
        } else if (xhr.status == 0) {
            // alert("link error");
        } else {
            alert("unhandled status " + xhr.status);
        }
    }

    var params = "";
    var keys = Object.keys(json);
    for (var i = 0; i < keys.length - 1; i++)
        params += keys[i] + "=" + json[keys[i]] + "&";
    params += keys[i] + "=" + json[keys[i]];
    console.log("send\n" + params);
    xhr.send(params);

}

function logout() {
    // submitted json formation
    var json = {
        "action": "login"
    };
    // server request and change back to login
    function change_to_login() {
        // document.getElementById("logout_div").hidden = true;
        // document.getElementById("login_div").hidden = false;

        // to the log in page
        window.location.href = "login.html";

        // clear all the data ###
    }
    restapi(json, change_to_login.bind(null));
}

// check login and do the action if login
function check_login(change_state){
    var json = {
        "action": "check_login"
    };

    restapi(json, change_state.bind(null));
}


async function check_exist(url) {
    try {
      const response = await fetch(url, { method: "HEAD" }); // Use the HEAD method to retrieve only the header, not the full content
  
      if (response.ok) {
        // Check the Content-Type header
        const contentType = response.headers.get("Content-Type");
        
        // use type check to prevent return a html by react server
        if (contentType.startsWith("image/")) { 
          return "image"; // Resource is an image
        } else if (contentType.startsWith("text/html")) {
          return "html"; // Resource is an HTML file
        } else if (contentType.startsWith("text/plain")) {
          return "text"; // Resource is a text file
        } else { // image can also be here
          return "other"; // Other types of resources
        }
      } else {
        return false; // Resource does not exist, status code outside the 2xx range
      }
    } catch (error) {
      return false; // Error occurred or resource does not exist
    }
  }

export { restapi, restapi_2, check_login, check_exist };

import React, { Component } from 'react';
import Reply from './reply';

// language option
import {dict_} from '../language';

class Anemail extends Component {
	constructor(props) {
        super(props);

        this.state = {reply_signal: false, 
                    reply_show: false};
    }

    componentDidUpdate(preProps){
        
    }


    handle_email_reply_generate(email){
        // mus t
        // this.setState({reply_content : "xxxx \n xxxx \n xxxx"});
        // !!!!!!!!!!!1 frist enable it
        this.setState({reply_show: true});

        const attrs = ["subject"]; // ,"from", "to","date" // wired ! date makes it generate wrong reply
        const content = attrs.map((attr, index) =>(email[attr]+"\n")) + "\n\n"+ document.getElementsByClassName("chat-messages")[0].textContent 

        const json = {
            "action": "email",
            "ops": "email_reply_generate",
            // "email": JSON.stringify(email), // must convert to String, otherwise, post can't send all info
            "email_content":  content
        };

        const formData = new URLSearchParams();
        for (const key in json) {
            formData.append(key, json[key]);
        }

        fetch("/action/", {
            method: "POST",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData // must stringify
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                console.log({ ...json, ...data });
                // must use state to pass prop, can't use ref
                this.setState({reply_signal: !this.state.reply_signal, 
                    reply_content: data["reply"]},()=>{
                        // this.reply_box.current.content = data["reply"];
                        // this.state.reply_content = data["reply"];
                });
            })
            .catch((error) => {
                console.error("Error to fetch the dialog with a talking:", error);
            });
    }


    part_show(part, index){
        var res;
        if (["text/plain"].indexOf(part.type) !== -1){
            const ordinary = {
                backgroundColor: 'white',
                padding: '10px',
                border: '0px',
                display: 'block',
                margin: '0',
                fontFamily: 'inherit',
                whiteSpace: 'pre' // show it as in normal
              };
            res = <pre style={ordinary}>{part.content}</pre>;
        } else if (["text/html"].indexOf(part.type) !== -1){
            res = <div dangerouslySetInnerHTML={{ __html: part.content }}></div>;
        } else if (["image/jpeg"].indexOf(part.type) !== -1){
            res = <div>
                    <img src={part.content} style={{maxWidth: "100%", maxHeight: "100%"}}></img>;
                </div>
        } else {
            res = part.type;
        }

        return (<div key={index}>
            {res}
        </div>);
    }

    
    render(){
        const attrs = ["Subject","From", "To","Date"];  // ,"email_id"
        const cellStyle = {
            borderBottom: '1px solid black',
            padding: '8px',
            textAlign: 'left'
          };
    
        var email = this.props.email;

        console.log("this email !", email);
    
        var reply;
        if (this.state.reply_show)
            reply =<Reply content={this.state.reply_content} email={this.props.email} reply_signal={this.state.reply_signal}/>;
        else 
            reply = <div></div>;
    
        
        return (
        <div>
            {/* Chat List */}
            <div className="chat-box">
                <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <tbody>
                        {attrs.map((attr, index) =>(
                            <tr key={index}>
                                <td style={cellStyle}>{attr}</td>
                                <td style={cellStyle}>{email[attr]}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
    
                {<div style={{ textAlign: 'right' }}>
                    <button onClick={this.handle_email_reply_generate.bind(this,email)}>email reply</button>
                </div>}
    
                <div className="chat-messages">
                    {email.parts.map((part, index)=>(
                        this.part_show(part, index)
                    ))}
                </div>
    
                {reply}
            </div>
        </div>);
    }
}


export default Anemail;

